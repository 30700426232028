<template>
  <section class="error-section">
    <div class="error-wrap">
      <dl class="error-box">
        <!-- 페이지를 찾을 수 없습니다. -->
        <dt>{{ $t('content.common.txt.txt08') }}</dt>
        <!-- 입력한 주소가 맞는지 다시 확인해 주세요. -->
        <dd>{{ $t('content.common.txt.txt09') }}</dd>
      </dl>
      <div class="btn-wrap">
        <!-- 메인으로 이동 -->
        <BaseButton
          @click="doNext"
        >{{ $t('content.common.txt.txt10') }}</BaseButton>
      </div>
    </div>
  </section>
</template>

<script>
import BaseButton from '@/components/base/BaseButton.vue';

export default {
  name: 'Error404',

  components: {
    BaseButton,
  },

  methods: {
    doNext() {
      this.$router.push({
        path: '/',
      });
    },
  },
};
</script>

<style scoped>
*{ margin:0; padding: 0;}
  .error-section {
    position: relative;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .error-section .error-wrap {
    position: relative;
  }

  .error-section .error-wrap .error-box {
    position: relative;
    padding: 98px 14px 40px;
    text-align: center;
  }

  .error-section .error-wrap .error-box dt:before {
    content: '';
    display: block;
    width: 68px;
    height: 68px;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    background: url('~@/assets/images/icons/failed.png') no-repeat center center;
  }

  .error-section .error-wrap .error-box dt {
    margin-top: 30px;
    font-size: 24px;
    font-weight: 700;
    color: #000;
  }

  .error-section .error-wrap .error-box dd {
    margin-top: 20px;
    font-size: 14px;
    font-weight: 500;
    color: #666;
    line-height: 2;
  }

  .error-section .error-wrap .btn-wrap {
    margin-bottom: 0;
  }
  .btn-wrap {
     text-align: center;
  }
  .btn-basic {
    position: relative;
    display: inline-flex;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
    height: 50px;
    font-size: 16px;
    font-weight: 700;
    text-align: center;
    color: #fff;
    border: 1px solid #000;
    outline: 0;
    background-color: #000;
    transition: 0.3s;
    padding: 0 20px;
  }
</style>
